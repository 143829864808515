<template>
    <div class="spacer uk-margin-large-top"></div>
    <div class="page smart-widget-page  uk-container margin-large-top">
        <div class="uk-card uk-card-default">
            <div class="uk-card-body">
                <div class="uk-card-title uk-margin-large-bottom uk-text-center">
                    <h1>{{ translate('nextchat.smartWidget.pageTitle') }}</h1>
                </div>
                <div class="page-content">
                    <div class="settings-block uk-flex uk-flex-gap-l  uk-margin-large-bottom uk-flex-between flex-break-medium-start">
                        <div class="settings-block__info" style="">
                            <div class="uk-panel">
                                <h3 class="settings-block__title">
                                    {{ translate('nextchat.smartWidget.buttonSettingsTitle') }}</h3>
                                <p class="settings-block__main-explain">
                                    {{ translate('nextchat.smartWidget.buttonSettingsExplain') }}</p>
                                <p>
                                    <strong>{{
                                            translate('nextchat.smartWidget.buttonPositionExplainTitle')
                                        }}: </strong><span>{{
                                        translate('nextchat.smartWidget.buttonPositionExplain')
                                    }}</span>
                                </p>
                            </div>
                        </div>
                        <div class="settings-block__form uk-width-expand ">
                            <div class="uk-card uk-card-default">
                                <div class="uk-card-body">
                                    <base-form
                                        @submit.prevent="handleStepOneSubmit"
                                        :config="stepOneFormConfig"
                                        action="#"
                                        v-model="builderSettings.button"
                                        method="post">

                                    </base-form>

                                    <h5 class="button-demo-explain uk-margin-remove-top uk-margin-small-bottom">
                                        {{ translate('nextchat.smartWidget.widgetPreview') }}</h5>
                                    <div class="button-demo-container">

                                        <div class="button-demo uk-flex uk-flex-middle uk-flex-center"
                                             :style="buttonDemoStyles"
                                             style="display : inline-block;flex: 0;width: auto;">
                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                 viewBox="0 0 32 32"
                                                 class="wa-icon"
                                                 :style="buttonIconStyles">
                                                <path
                                                    d=" M19.11 17.205c-.372 0-1.088 1.39-1.518 1.39a.63.63 0 0 1-.315-.1c-.802-.402-1.504-.817-2.163-1.447-.545-.516-1.146-1.29-1.46-1.963a.426.426 0 0 1-.073-.215c0-.33.99-.945.99-1.49 0-.143-.73-2.09-.832-2.335-.143-.372-.214-.487-.6-.487-.187 0-.36-.043-.53-.043-.302 0-.53.115-.746.315-.688.645-1.032 1.318-1.06 2.264v.114c-.015.99.472 1.977 1.017 2.78 1.23 1.82 2.506 3.41 4.554 4.34.616.287 2.035.888 2.722.888.817 0 2.15-.515 2.478-1.318.13-.33.244-.73.244-1.088 0-.058 0-.144-.03-.215-.1-.172-2.434-1.39-2.678-1.39zm-2.908 7.593c-1.747 0-3.48-.53-4.942-1.49L7.793 24.41l1.132-3.337a8.955 8.955 0 0 1-1.72-5.272c0-4.955 4.04-8.995 8.997-8.995S25.2 10.845 25.2 15.8c0 4.958-4.04 8.998-8.998 8.998zm0-19.798c-5.96 0-10.8 4.842-10.8 10.8 0 1.964.53 3.898 1.546 5.574L5 27.176l5.974-1.92a10.807 10.807 0 0 0 16.03-9.455c0-5.958-4.842-10.8-10.802-10.8z"
                                                    fill-rule="evenodd"></path>
                                            </svg>
                                            <span class="button-text"
                                                  style="line-height: 36px;"
                                                  v-show="builderSettings.button.buttonText != ''">{{
                                                    builderSettings.button.buttonText
                                                }}</span>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        class="settings-block uk-flex uk-flex-gap-l uk-margin-large-bottom uk-flex-between flex-break-medium-start">
                        <div class="settings-block__info" style="">
                            <div class="uk-panel">
                                <h3 class="settings-block__title">
                                    {{ translate('nextchat.smartWidget.widgetSettingsTitle') }}</h3>
                                <p class="settings-block__main-explain">
                                    {{ translate('nextchat.smartWidget.widgetSettingsExplain') }}</p>
                                <p>
                                    <strong>{{
                                            translate('nextchat.smartWidget.userMessageExplainTitle')
                                        }}: </strong><span>{{
                                        translate('nextchat.smartWidget.userMessageExplain')
                                    }}</span>
                                </p>
                            </div>
                        </div>
                        <div class="settings-block__form uk-width-expand ">
                            <div class="uk-card uk-card-default">
                                <div class="uk-card-body">
                                    <base-form
                                        @submit.prevent="handleStepOneSubmit"
                                        :config="stepTwoFormConfig"
                                        action="#"
                                        ref="form2"
                                        v-model="builderSettings.widget"
                                        method="post">

                                    </base-form>

                                    <h5 class="widget-demo-explain uk-margin-remove-top uk-margin-small-bottom">
                                        {{ translate('nextchat.smartWidget.widgetPreview') }}</h5>
                                    <div class="widget-demo-container">
                                        <div class="ncwa-widget"
                                             style="display: block; width: 360px; max-width: 90vw; min-height: 100px;box-shadow: rgba(0, 0, 0, 0.4) 2px 2px 6px;font: 400 15px / 1.3 -apple-system, BlinkMacSystemFont, Roboto, sans-serif;"
                                             :style="{'border-radius': builderSettings.widget.borderRadius +'px'}">
                                            <div class="ncwa-header"
                                                 style="padding: 25px; display: flex; gap: 12px;"
                                                 :style="{
                                                     'background-color': builderSettings.widget.backgroundColor,
                                                     'border-top-left-radius': builderSettings.widget.borderRadius +'px',
                                                     'border-top-right-radius': builderSettings.widget.borderRadius +'px'
                                                 }">
                                                <div class="ncwa-header__image"
                                                     style="height: 50px; width:50px; background-position: center; background-repeat: no-repeat; background-size: 40px auto; padding: 5px; box-shadow: rgba(0, 0, 0, 0.4) 2px 2px 6px; border-radius: 50%;"
                                                     :style="{'background-image': 'url(\''+builderSettings.widget.brandImageUrl+'\')', 'background-color': builderSettings.widget.backgroundColor,}">

                                                </div>
                                                <div class="ncwa-header__text"
                                                     style="color: white; display: flex;flex-direction: column; justify-content: center;gap: 4px">
                                                    <div class="ncwa-header__title"
                                                         style="font-weight: bold; font-size: 16px;">
                                                        <span>{{ builderSettings.widget.brandName }}</span>
                                                    </div>
                                                    <div class="ncwa-header__content" style="font-size: 13px;">
                                                        <span>{{ builderSettings.widget.brandSubTitle }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="ncwa-chat-sim"

                                                 :style="{'background-image': 'url(\''+builderSettings.widget.widgetChatBackgroundUrl+'\')'}"
                                                 style="min-height: 120px; background-position: top left;">
                                                <div class="ncwa-message-sim"
                                                     style="margin: 20px 10px; background: white; display: inline-block; padding: 10px; border-radius: 10px;">
                                                    <div class="ncwa-message-sim__title"
                                                         style="font-size: 13px; font-weight: 700; line-height: 18px; color: rgba(0, 0, 0, 0.4);">
                                                        <span>{{ builderSettings.widget.brandName }}</span>
                                                    </div>
                                                    <div class="ncwa-message-sim__text"
                                                         style="font-size: 14px;line-height: 19px;margin-top: 4px;color: rgb(17, 17, 17);">
                                                        <p style="white-space: pre;margin: 0;">
                                                            {{ builderSettings.widget.welcomeText }}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="ncwa-footer"
                                                 style="background: white; padding: 20px;text-align: center;"
                                                 :style="{
                                                     'border-bottom-left-radius': builderSettings.widget.borderRadius +'px',
                                                     'border-bottom-right-radius': builderSettings.widget.borderRadius +'px'
                                                 }">
                                                <div class="button-demo uk-flex uk-flex-middle uk-flex-center"
                                                     style="cursor: pointer;padding: 8px 12px;display :flex; width: 100%; justify-content: center; gap: 4px;"
                                                     :style="{
                                                        'background-color':  builderSettings.button.backgroundColor,
                                                        'color'           :  builderSettings.button.contentColor,
                                                        'border-radius'   : + builderSettings.button.borderRadius + 'px',
                                                    }"
                                                >
                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                         viewBox="0 0 32 32"
                                                         class="wa-icon"
                                                         style="width: 20px; height : 20px; z-index: 1;"
                                                         :style="{fill: builderSettings.button.contentColor}">
                                                        <path
                                                            d=" M19.11 17.205c-.372 0-1.088 1.39-1.518 1.39a.63.63 0 0 1-.315-.1c-.802-.402-1.504-.817-2.163-1.447-.545-.516-1.146-1.29-1.46-1.963a.426.426 0 0 1-.073-.215c0-.33.99-.945.99-1.49 0-.143-.73-2.09-.832-2.335-.143-.372-.214-.487-.6-.487-.187 0-.36-.043-.53-.043-.302 0-.53.115-.746.315-.688.645-1.032 1.318-1.06 2.264v.114c-.015.99.472 1.977 1.017 2.78 1.23 1.82 2.506 3.41 4.554 4.34.616.287 2.035.888 2.722.888.817 0 2.15-.515 2.478-1.318.13-.33.244-.73.244-1.088 0-.058 0-.144-.03-.215-.1-.172-2.434-1.39-2.678-1.39zm-2.908 7.593c-1.747 0-3.48-.53-4.942-1.49L7.793 24.41l1.132-3.337a8.955 8.955 0 0 1-1.72-5.272c0-4.955 4.04-8.995 8.997-8.995S25.2 10.845 25.2 15.8c0 4.958-4.04 8.998-8.998 8.998zm0-19.798c-5.96 0-10.8 4.842-10.8 10.8 0 1.964.53 3.898 1.546 5.574L5 27.176l5.974-1.92a10.807 10.807 0 0 0 16.03-9.455c0-5.958-4.842-10.8-10.802-10.8z"
                                                            fill-rule="evenodd"></path>
                                                    </svg>
                                                    <span class="button-text"
                                                          style="line-height: 20px; font-size: 15px;font-weight: 700;"
                                                          v-show="builderSettings.button.buttonText != ''">
                                                            {{ builderSettings.widget.ctaText }}</span>
                                                </div>
                                                <div class="credits" style="margin-top: 10px; font-size: 12px;">
                                                    <span>Powered by <a target="_blank" href="https://nextchats.com">nextchats.com</a></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>


                    <div
                        class="settings-block uk-flex uk-flex-gap-l uk-margin-large-bottom uk-flex-between flex-break-medium-start">
                        <div class="settings-block__info" style="">
                            <div class="uk-panel">
                                <h3 class="settings-block__title">
                                    {{ translate('nextchat.smartWidget.widgetCodeTitle') }}</h3>
                                <p class="settings-block__main-explain">
                                    {{ translate('nextchat.smartWidget.widgetCodeExplain') }}</p>
                            </div>
                        </div>
                        <div class="settings-block__form uk-width-expand ">
                            <div class="uk-card uk-card-default">
                                <div class="uk-card-body">
<pre style="direction: ltr;text-align:left;"  v-if="canShowCode">
&lt;script&gt;
    {{ widgetCode }}
&lt;/script&gt;
</pre>


<pre style="direction: ltr;text-align:left;"  v-if=" ! canShowCode">
{{translate('nextchat.smartWidget.cantShowCodeError')}}
</pre>

                       </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </div>


    </div>
</template>


<script setup>
import {nextTick} from "vue";
import {useMeta} from "vue-meta";
import {useI18n} from "vue-i18n/index";
import {useStore} from "vuex";
import asyncOperations from '@/client/extensions/composition/asyncOperations.js';

const props = defineProps({});

let {asyncOps, asyncOpsReady, asyncStatus} = asyncOperations(props, useStore());
let {meta}                                 = useMeta({title: useI18n().t('nextchat.smartWidget.mainTitle')}).meta


</script>
<script>

// TODO: generate embed code (finish)
// todo: deploy
export default {
  //  layout    : 'dashboard',
    middleware: {},
    data() {
        return {
            isMounted : false,
            config             : config,
            stepOneFormConfig: {
                fields      : {
                    backgroundColor: {
                        name : 'backgroundColor',
                        type : 'color',
                        label: 'nextchat.widgetForm.buttonBackgroundColorLabel',
                        group: 'group1',
                    },
                    contentColor   : {
                        name : 'contentColor',
                        type : 'color',
                        label: 'nextchat.widgetForm.contentColorLabel',
                        group: 'group1',
                    },
                    buttonText     : {
                        name : 'buttonText',
                        type : 'text',
                        label: 'nextchat.widgetForm.buttonTextLabel',
                        group: 'group1',
                    },
                    borderRadius   : {
                        name      : 'borderRadius',
                        type      : 'text',
                        label     : 'nextchat.widgetForm.buttonBorderRadiusLabel',
                        group     : 'group1',
                        validation: {
                            'required': {},
                            'integer' : {}
                        },
                    },
                    offsetBottom   : {
                        name      : 'offsetBottom',
                        type      : 'text',
                        label     : 'nextchat.widgetForm.offsetBottomLabel',
                        group     : 'group1',
                        validation: {
                            'required': {},
                            'integer' : {}
                        },
                    },
                    offsetRight    : {
                        name      : 'offsetRight',
                        type      : 'text',
                        label     : 'nextchat.widgetForm.offsetRightLabel',
                        group     : 'group1',
                        validation: {
                            'required': {},
                            'integer' : {}
                        },
                    },
                    offsetLeft     : {
                        name      : 'offsetLeft',
                        type      : 'text',
                        label     : 'nextchat.widgetForm.offsetLeftLabel',
                        group     : 'group1',
                        validation: {
                            'required': {},
                            'integer' : {}
                        },
                    },
                    position       : {
                        name      : 'position',
                        type      : 'radio',
                        label     : 'nextchat.widgetForm.positionLabel',
                        group     : 'group1',
                        list      : [
                            {
                                value: 'right',
                                label: 'nextchat.widgetForm.positionRightLabel'
                            }, {
                                value: 'left',
                                label: 'nextchat.widgetForm.positionLeftLabel'
                            }
                        ],
                        validation: {
                            'required': {},
                        },
                    },

                },
                selfHandling: false,
                groups      : {
                    group1: {
                        component: 'block',
                        props    : {
                            class: 'uk-flex uk-flex-wrap uk-flex-start uk-gap-m flex-break-medium-start'
                        }

                    }
                }
            },
            stepTwoFormConfig: {
                fields      : {
                    brandName      : {
                        name : 'brandName',
                        type : 'text',
                        label: 'nextchat.widgetForm.brandNameLabel',
                        group: 'group1',
                    },
                    brandSubTitle  : {
                        name : 'brandSubTitle',
                        type : 'text',
                        label: 'nextchat.widgetForm.brandSubTitleLabel',
                        group: 'group1',
                    },
                    phoneNumber    : {
                        name      : 'phoneNumber',
                        type      : 'text',
                        label     : 'nextchat.widgetForm.phoneNumberLabel',
                        group     : 'group1',
                        validation: {
                            required       : {},
                            i18nPhoneNumber: {}

                            /*
                             minimum: {
                             params: {
                             min: 9
                             }
                             }
                             */
                        }
                    },
                    welcomeText    : {
                        name      : 'welcomeText',
                        type      : 'textarea',
                        label     : 'nextchat.widgetForm.welcomeTextLabel',
                        group     : 'group1',
                        validation: {
                            'required': {},
                        },
                    },
                    userMessage    : {
                        name         : 'userMessage',
                        type         : 'textarea',
                        label        : this.translate('nextchat.widgetForm.userMessageLabel'),
                        autoTranslate: false,
                        group        : 'group1',
                        validation   : {
                            'required': {},
                        },
                    },
                    brandImageUrl  : {
                        name      : 'brandImageUrl',
                        type      : 'text',
                        label     : 'nextchat.widgetForm.brandImageUrlLabel',
                        group     : 'group1',
                        validation: {
                            'required': {},
                        },
                    },
                    backgroundColor: {
                        name      : 'backgroundColor',
                        type      : 'color',
                        label     : 'nextchat.widgetForm.widgetBackgroundColorLabel',
                        group     : 'group1',
                        validation: {
                            'required': {},
                        },
                    },
                    ctaText        : {
                        name      : 'ctaText',
                        type      : 'text',
                        label     : 'nextchat.widgetForm.ctaTextLabel',
                        group     : 'group1',
                        validation: {
                            'required': {},
                        },
                    },
                    borderRadius   : {
                        name      : 'borderRadius',
                        type      : 'text',
                        label     : 'nextchat.widgetForm.widgetBorderRadiusLabel',
                        group     : 'group1',
                        validation: {
                            'required': {},
                            'integer' : {}
                        },
                    },
                    openByDefault  : {
                        name      : 'openByDefault',
                        type      : 'radio',
                        label     : 'nextchat.widgetForm.openByDefaultLabel',
                        group     : 'group1',
                        list      : [
                            {
                                value: 'false',
                                label: 'nextchat.widgetForm.openByDefaultClosedLabel'
                            },
                            {
                                value: 'true',
                                label: 'nextchat.widgetForm.openByDefaultOpenLabel'
                            },

                        ],
                        validation: {
                            'required': {},
                        },
                    },

                },
                selfHandling: false,
                groups      : {
                    group1: {
                        component: 'block',
                        props    : {
                            class: 'uk-flex uk-flex-wrap uk-flex-start uk-gap-m flex-break-medium-start'
                        }

                    }
                }
            },
            builderSettings  : {
                button: {
                    backgroundColor: '#4DC247',
                    contentColor   : '#ffffff',
                    buttonText     : 'טקסט בדיקה',
                    borderRadius   : '50',
                    offsetBottom   : '25',
                    offsetRight    : '25',
                    offsetLeft     : '25',
                    position       : 'right'
                },
                widget: {
                    brandName      : this.translate('nextchat.widgetForm.defaultBrandName'),
                    brandSubTitle  : this.translate('nextchat.widgetForm.defaultBrandSubTitle'),
                    phoneNumber    : '',
                    welcomeText    : this.translate('nextchat.widgetForm.defaultWelcomeText'),
                    userMessage    : this.translate('nextchat.widgetForm.defaultUserMessage'),
                    brandImageUrl  : config.nextchat.widgetDefaultBrandImageUrl,//TODO: fix this
                    backgroundColor: '#0a5f54',
                    ctaText        : this.translate('nextchat.widgetForm.defaultCtaText'),
                    borderRadius   : '25',
                    openByDefault  : 'false',
                    widgetChatBackgroundUrl : config.nextchat.widgetChatBackgroundUrl,
                }
            },
            asyncData        : {
                /*
                 summaryData: {
                 target: 'project/summary',
                 data: {
                 'from': computed(() => {
                 let d = new Date();
                 d.setFullYear( d.getFullYear() - 1);
                 d.setDate(1);
                 return d.toISOString().split('T')[0]
                 })
                 }
                 },
                 statuses: 'project/status'
                 */
            },
        }
    },
    components: {},
    computed  : {
        widgetButtonDemoStyles() {
            let settings = {...this.builderSettings.button};
            return {
                'padding'         : settings.buttonText != '' ? '5px 10px' : '5px',
                'background-color': settings.backgroundColor,
                'color'           : settings.contentColor,
                'border-radius'   : +settings.borderRadius + 'px',
            };

        },
        buttonDemoStyles() {
            let settings = {...this.builderSettings.button};
            let result   = {
                'position'        : 'absolute',
                'bottom'          : +settings.offsetBottom + 'px',
                'padding'         : settings.buttonText != '' ? '5px 10px' : '5px',
                'background-color': settings.backgroundColor,
                'color'           : settings.contentColor,
                'border-radius'   : +settings.borderRadius + 'px',
            };

            if (settings.position === 'right') {
                result.right = +settings.offsetRight + 'px';
                result.left  = 'auto';
            }

            if (settings.position === 'left') {
                result.left  = +settings.offsetLeft + 'px';
                result.right = 'auto';
            }
            return result
        },
        buttonIconStyles() {
            let result = {
                width    : '36px',
                height   : '36px',
                fill     : this.builderSettings.button.contentColor,
                'z-index': '1',
            };

            return result
        },
        widgetCode() {
            if ( ! this.canShowCode) {
                return this.translate('nextchat.smartWidget.cantShowCodeError');
            }
            let settings = JSON.stringify({...this.builderSettings}, null, "\t");
            let suffix = this.getRandomScriptSuffix();

            // let the indentation be like this for readability
let code = `
    var url     = '${config.nextchat.baseWidgetUrl}${suffix}';
    var s       = document.createElement('script');
    var x       = document.getElementsByTagName('script')[0];
    var options = ${settings};
    s.type      = 'text/javascript';
    s.async     = true;
    s.src       = url;
    s.onload    = function () {
        window.dispatchEvent((new CustomEvent('nextchat:request-create-widget', {'detail': {options}})));
    };
    x.parentNode.insertBefore(s, x);
`;

            return code;
        },
        canShowCode () {
            return this.isMounted && this.$refs.form2.isTouched && this.$refs.form2.isValid;

        }
    },
    methods   : {
        handleStepOneSubmit() {
            console.log('step one submit');
        },
        destroyWidget() {
            let ev = new CustomEvent('nextchat:request-destroy-widget');
            window.dispatchEvent(ev);
        },
        getRandomScriptSuffix () {
            return '?' + Math.floor(Math.random() * 100000);
        },
        insertWidget() {
            var url     = config.nextchat.baseWidgetUrl + this.getRandomScriptSuffix();
            var s       = document.createElement('script');
            var x       = document.getElementsByTagName('script')[0];
            var options = {...this.builderSettings};
            s.type      = 'text/javascript';
            s.async     = true;
            s.src       = url;
            s.onload    = function () {
                window.dispatchEvent((new CustomEvent('nextchat:request-create-widget', {'detail': {options}})));
            };

            x.parentNode.insertBefore(s, x);
            /*
            var url     = 'https://wati-integration-service.clare.ai/ShopifyWidget/shopifyWidget.js?93761';
            var s       = document.createElement('script');
            var x       = document.getElementsByTagName('script')[0];
            var options = {
                "enabled"          : true,
                "chatButtonSetting": {
                    "backgroundColor": "#4dc247",
                    "ctaText"        : "",
                    "borderRadius"   : "25",
                    "marginLeft"     : "0",
                    "marginBottom"   : "50",
                    "marginRight"    : "50",
                    "position"       : "right"
                },
                "brandSetting"     : {
                    "brandName"      : "WATI",
                    "brandSubTitle"  : "Typically replies within a day",
                    "brandImg"       : "https://cdn.clare.ai/wati/images/WATI_logo_square_2.png",
                    "welcomeText"    : "Hi there!\nHow can I help you?",
                    "messageText"    : "{{page_title}}Hello, I have a question about {{page_link}}",
                    "backgroundColor": "#0a5f54",
                    "ctaText"        : "Start Chat",
                    "borderRadius"   : "25",
                    "autoShow"       : false,
                    "phoneNumber"    : "972544894585"
                }
            };
            s.type      = 'text/javascript';
            s.async     = true;
            s.src       = url;
            s.onload    = function () {
                CreateWhatsappChatWidget(options);
            };

            x.parentNode.insertBefore(s, x);
            */
        }
    },
    mounted() {
      //  this.insertWidget();
        nextTick(() => {
            this.isMounted = true;
        });
    },
    unmounted() {
        nextTick(() => {
            try {
                this.isMounted = false;
            } catch (e) {

            }

        });
    },
    watch: {
        builderSettings: {
            deep: true,
            handler: function () {
             //   this.destroyWidget();
            //    this.insertWidget();
            }
        }
    },

}
</script>

<style lang="scss" scoped>
.settings-block {
    &__title {
        font-size     : 22px;
        font-weight   : 600;
        margin-bottom : calc(var(--global-margin) * 0.25);
    }

    &__info {
        flex : 0 0 300px;
    }

    &__form {
        flex : 100%;
    }

    p {
        margin-top : 0;
    }

    &__main-explain {
        font-size : 17px;
    }


}

.button-demo-wrapper {
    height     : 300px;
    width      : 300px;
    padding    : 10px;
    background : #dedede;
    border     : 1px solid #3e3e3e3e;

}

.button-demo-container {
    position   : relative;
    border     : 1px solid #eeeeee;
    box-shadow : 0 0 12px 0 rgba(0, 0, 0, 0.05);
    padding    : 15px;
    height     : 150px;
    width      : 300px;
    transition : all 200ms ease-out;
}

.button-demo {
    transition : all 200ms ease-out;

    .wa-icon {
        transition : all 200ms ease-out;
    }

    .button-text {
        transition : all 200ms ease-out;
    }
}

</style>

<style lang="scss">
.smart-widget-page .settings-block .input-wrapper {
    flex      : 30%;
    min-width : 250px;
    max-width : 30%;
}
</style>
